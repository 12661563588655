<template>
  <div class="grid-container">
    <div class="grid-x grid-padding-x pt-10">
      <div class="cell small-12 text-center">
        <h1>Payment</h1>
      </div>
    </div>
    <div :class="{ paymentProcessing: (processing === true) }" class="bordered pb-4">
      <div class="grid-x grid-padding-x pt-2">
        <div class="cell small-12 medium-6">
          <div
            v-if="token.card === false"
            class="cell small-12 medium-8 medium-offset-1 pad-bottom">
            <p class="lead pad-bottom">Please enter your payment card details below</p>
            <StripeElements
              :stripe-key="stripeKey"
              #default="{ elements }"
              ref="elms"
            >
              <StripeElement
                type="card"
                :elements="elements"
                ref="card"
              />
            </StripeElements>

            <p class="help-text form pt-5">
              By clicking "Submit your order" you agree to our
              <a class="link" target="_blank" href="/terms">terms and conditions</a>
              and <a class="link" target="_blank" href="/shipping-policy">
              delivery and returns policy</a>. Your card will be charged
              {{ totals | priceInPounds | currency }}.
            </p>
            <p class="help-text form">
              Please note your card will be charged immediately
            </p>
          </div>
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div class="cell small-8 medium-6">
          <button
            @click="pay"
            class="button rounded-tall expanded">
            Pay {{ totals | priceInPounds | currency }}
          </button>
        </div>
      </div>
    </div>
    <div v-if="processing === true">
      <div class="grid-x grid-padding-x">
        <div class="cell small-8 medium-6">
          <h2>Your payment is being processed.</h2>
          <p>This will take a few seconds, please do not refresh or leave this page.</p>
        </div>
      </div>
    </div>
    <div class="message" @click="message = ''" v-if="message.length > 0">
      {{ message }}
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import { StripeElements, StripeElement } from 'vue-stripe-elements-plus'
import axios from '../axios';

export default {
  name: 'Payment',
  components: {
    StripeElements,
    StripeElement,
  },
  data() {
    return {
      totals: {},
      complete: false,
      token: {
        card: false,
      },
      intent: '',
      processing: false,
      message: '',
      stripeKey: 'pk_live_wF4udEJ0NihY5oxpgFjxpcGp001FSWrZxd',
    };
  },
  computed: {
    orderId() {
      return this.$store.state.order.orderData.id;
    },
  },
  methods: {
    getOrderTotals() {
      axios.get(`/orders/getOrder/${this.orderId}.json`)
        .then((response) => {
          this.totals = response.data.totalPrice;
        });
    },
    pay() {
      this.processing = true;
      const groupComponent = this.$refs.elms;
      const cardComponent = this.$refs.card;
      const cardElement = cardComponent.stripeElement;
      groupComponent.instance.createToken(cardElement).then((result) => {
        if (result.error) {
          this.processing = false;
          this.message = result.error.message;
        } else {
          const postData = {};
          postData.token = result.token;
          postData.amount = this.totals;
          postData.order = this.orderId;
          axios.post('/Stripe/chargeCard.json', postData)
            .then((response) => {
              if (response.data.object === 'charge' && response.data.captured === true) {
                this.updateOrder();
              } else {
                // eslint-disable-next-line
                this.message = 'Your payment could not be processed. Please check your card details and try again.';
                this.processing = false;
              }
            });
        }
      });
    },
    updateOrder() {
      const postData = {};
      postData.basket = this.$store.state.basketId;
      postData.customer = this.$store.state.customer;
      postData.order = this.orderId;
      axios.post('/orders/complete.json', postData)
        .then((response) => {
          const orderId = response.data.id;
          this.$router.push(`/thank-you/${orderId}`);
        });
    },
  },
  mounted() {
    this.getOrderTotals();
  },
};
</script>

<style lang="scss" scoped>
.paymentProcessing {
  display: none;
}
</style>
